import { Grid, LinearProgress, Tooltip, Typography } from "@mui/material";
import { useGetLatestStatistic } from "../../../api/engine";
import { Statistics } from "../../../api/api.generated";
import AlertMessage from "../../../components/alert-banners/alert-message";
import { AlertType } from "../../../components/alert-banners/alert-banner";
import {
	CPU_ATT,
	CPU_USED,
	MEMORY_ATT_TYPE_HEAP,
	MEMORY_IN_USE,
	MEMORY_TOTAL,
	ParseStatType,
	SPACE_ATT_PATH_DATA,
	SPACE_AVAILABLE,
	SPACE_TOTAL,
} from "../../../rhapsody-stats";
import { HumanizeBytes } from "../../../utils/byte-util";
import "./engine-stats.scss";

type ChartData = {
	inUsePercentage: number;
	tooltip: string;
	title: string;
};

const getValue = (
	statistic: Statistics,
	key: string,
	attributes: { [key: string]: string }
): number | null => {
	const data = ParseStatType(statistic, key, attributes);
	if (data) {
		return data[0][1];
	}
	return null;
};

const ParseCPU = (statistic: Statistics) => {
	const cpu = getValue(statistic, CPU_USED, CPU_ATT);
	if (cpu == null) {
		return null;
	}

	const cpuUsed = Math.round(cpu * 100);
	return {
		inUsePercentage: cpuUsed,
		tooltip: `${cpuUsed}% in use`,
		title: "CPU",
	};
};

const ParseMemory = (statistic: Statistics) => {
	const memoryTotalValue = getValue(
		statistic,
		MEMORY_TOTAL,
		MEMORY_ATT_TYPE_HEAP
	);
	const memoryInUseValue = getValue(
		statistic,
		MEMORY_IN_USE,
		MEMORY_ATT_TYPE_HEAP
	);

	if (memoryTotalValue == null || memoryInUseValue == null) {
		return null;
	}

	let memoryInUseRatio = 0;
	if (memoryTotalValue !== 0) {
		memoryInUseRatio = Math.round(
			(memoryInUseValue / memoryTotalValue) * 100
		);
	}
	return {
		inUsePercentage: memoryInUseRatio,
		tooltip: `${HumanizeBytes(
			memoryInUseValue,
			""
		)} used of ${HumanizeBytes(memoryTotalValue, "")}`,
		title: "Memory",
	};
};

const ParseDiskSpace = (statistic: Statistics) => {
	const diskSpaceTotalValue = getValue(
		statistic,
		SPACE_TOTAL,
		SPACE_ATT_PATH_DATA
	);
	const diskSpaceFreeValue = getValue(
		statistic,
		SPACE_AVAILABLE,
		SPACE_ATT_PATH_DATA
	);

	if (diskSpaceTotalValue == null || diskSpaceFreeValue == null) {
		return null;
	}

	const diskSpaceInUse = diskSpaceTotalValue - diskSpaceFreeValue;

	const diskSpaceUseRatio =
		diskSpaceTotalValue !== 0
			? Math.round((diskSpaceInUse / diskSpaceTotalValue) * 100)
			: 0;
	return {
		inUsePercentage: diskSpaceUseRatio,
		tooltip: `${HumanizeBytes(
			diskSpaceFreeValue,
			""
		)} free of ${HumanizeBytes(diskSpaceTotalValue, "")}`,
		title: "Disk",
	};
};

/**
 * @param statistic the statistics
 * @returns the tile data
 */
export const getTileData = (statistic?: Statistics) => {
	if (!statistic) {
		return [];
	}

	const cpu = ParseCPU(statistic);
	const memory = ParseMemory(statistic);
	const diskSpace = ParseDiskSpace(statistic);

	const tileData: ChartData[] = [];
	if (cpu) {
		tileData.push(cpu);
	}
	if (memory) {
		tileData.push(memory);
	}
	if (diskSpace) {
		tileData.push(diskSpace);
	}
	return tileData;
};

/**
 * @param chartData the chart data
 * @returns the colour of  the data based on thresholds of 50 and 80
 */
export const getColour = (chartData: ChartData) => {
	if (chartData.inUsePercentage > 80) {
		return "red";
	}
	if (chartData.inUsePercentage > 50) {
		return "orange";
	}
	return "green";
};

type EngineStatsProps = {
	id: string;
};

/**
 * @param param0 the parameters as EngineStatsProps
 * @returns the engine stat tiles for cpu, memory and disk space
 */
const EngineStats = ({ id }: EngineStatsProps) => {
	const { stats, isSuccess } = useGetLatestStatistic(
		id,
		(data, apiStatus) => {
			if (apiStatus.isSuccess && data) {
				return {
					stats: getTileData(data),
					isSuccess: true,
					error: null,
				};
			}
			return {
				stats: undefined,
				isSuccess: apiStatus.isSuccess,
				error: apiStatus.error,
			};
		}
	);

	if (!isSuccess) {
		return (
			<AlertMessage
				alertType={AlertType.Error}
				message="Error loading statistics!"
			/>
		);
	}

	if (!stats || stats.length === 0) {
		return (
			<AlertMessage
				alertType={AlertType.Info}
				message="No stats to display!"
			/>
		);
	}

	return (
		<Grid container spacing={2}>
			{stats.map((chartData: ChartData, index: number) => (
				<Grid
					item
					xs={4}
					key={index}
					textAlign="center"
					data-testid={`stat-tile-${chartData.title}`}
					aria-label={`${chartData.inUsePercentage} percent ${chartData.title} in use`}
				>
					<Tooltip
						placement="top"
						title={chartData.tooltip}
						key={index}
						arrow
						aria-disabled="true"
						PopperProps={{
							modifiers: [
								{
									name: "offset",
									options: {
										offset: [0, -7],
									},
								},
							],
						}}
					>
						<LinearProgress
							variant="determinate"
							value={chartData.inUsePercentage}
							className={`${getColour(chartData)}-progress-bar`}
						/>
					</Tooltip>
					<Typography
						aria-disabled="true"
						variant="body2"
						color="text.secondary"
					>
						{chartData.title}
					</Typography>
				</Grid>
			))}
		</Grid>
	);
};

export default EngineStats;
