import { ExpandMore } from "@mui/icons-material";
import {
	Box,
	Typography,
	useTheme,
	useMediaQuery,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material";
import EngineAccordion from "./accordion/engine-accordion";
import EngineTable from "./table/engine-table";
import { useGetAllEngines } from "../../../../api/engine";

type EnginesProps = {
	org: string;
};

/**
 * @param props the parameters as EngineProps
 * @returns the engines on the manage page
 */
const Engines = (props: EnginesProps) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));
	const { engines } = useGetAllEngines((data, apiStatus) => {
		if (apiStatus.isSuccess && data) {
			return { engines: data, apiStatus };
		}
		return { engines: [], apiStatus };
	}, props.org);

	return (
		<Box>
			<Accordion defaultExpanded={true}>
				<AccordionSummary
					expandIcon={<ExpandMore />}
					aria-controls="open-engines-table"
					data-testid="open-engines-table"
				>
					<Typography variant="h4">Engines</Typography>
				</AccordionSummary>
				<AccordionDetails>
					{engines.length === 0 ? (
						<Typography
							textAlign="center"
							paddingBottom="2.2em"
							fontStyle="italic"
							color="GrayText"
						>
							No Engines
						</Typography>
					) : (
						<Box>
							{mobile ? (
								<Box data-testid="engine-accordion">
									<EngineAccordion org={props.org} />
								</Box>
							) : (
								<Box data-testid="engine-table">
									<EngineTable org={props.org} />
								</Box>
							)}
						</Box>
					)}
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};

export default Engines;
