import {
	ToggleButtonGroup,
	ToggleButton,
	Tooltip,
	ToggleButtonProps,
	TooltipProps,
} from "@mui/material";
import EngineEvents from "../../../components/events/engine-events";
import { FC } from "react";
import { useGetEvents } from "../../../api/engine";
import {
	EventSeverity,
	MapOpenTelemetrySeverityToEventSeverity,
} from "../../../rhapsody-stats";

type HandleEventSelectProps = (
	_: React.MouseEvent<HTMLElement>,
	newSeverity: string[]
) => void;
export type EngineTileEventProps = {
	id: string;
	severity: string[];
	handleSelect: HandleEventSelectProps;
};

/**
 * Button group showing count of events from Rhapsody engine by severity.
 * Shows events when clicked.
 *
 * @param param0 the parameters as EngineTileEventProps
 * @returns engine tile events element
 */
const EngineTileEvents = ({
	id,
	severity,
	handleSelect,
}: EngineTileEventProps) => {
	const { warn, alarm, escalation } = useGetEvents(id, (data, apiStatus) => {
		if (apiStatus.isSuccess && data) {
			const warn = data.reduce(
				(count, event) =>
					MapOpenTelemetrySeverityToEventSeverity(event.severity) ===
					EventSeverity.WARNING
						? count + 1
						: count + 0,
				0
			);
			const alarm = data.reduce(
				(count, event) =>
					MapOpenTelemetrySeverityToEventSeverity(event.severity) ===
					EventSeverity.ALARM
						? count + 1
						: count + 0,
				0
			);
			const escalation = data.reduce(
				(count, event) =>
					MapOpenTelemetrySeverityToEventSeverity(event.severity) ===
					EventSeverity.ESCALATION
						? count + 1
						: count + 0,
				0
			);
			return { warn, alarm, escalation };
		}
		return { events: [] };
	});

	return (
		<>
			<ToggleButtonGroup
				value={severity}
				onChange={handleSelect}
				color="primary"
				data-testid="events-alerts-list"
			>
				{warn ? (
					<TooltipToggleButton
						TooltipProps={{ title: EventSeverity.WARNING }}
						value={EventSeverity.WARNING}
						data-testid="events-button-warn"
						aria-label={`Warning count: ${warn}`}
					>
						<EngineEvents
							severity={EventSeverity.WARNING}
							count={warn}
						/>
					</TooltipToggleButton>
				) : null}
				{alarm ? (
					<TooltipToggleButton
						TooltipProps={{ title: EventSeverity.ALARM }}
						value={EventSeverity.ALARM}
						data-testid="events-button-alarm"
						aria-label={`Alarm count: ${alarm}`}
					>
						<EngineEvents
							severity={EventSeverity.ALARM}
							count={alarm}
						/>
					</TooltipToggleButton>
				) : null}
				{escalation ? (
					<TooltipToggleButton
						TooltipProps={{ title: EventSeverity.ESCALATION }}
						value={EventSeverity.ESCALATION}
						data-testid="events-button-escalation"
						aria-label={`Escalation count: ${escalation}`}
					>
						<EngineEvents
							severity={EventSeverity.ESCALATION}
							count={escalation}
						/>
					</TooltipToggleButton>
				) : null}
			</ToggleButtonGroup>
		</>
	);
};

type TooltipToggleButtonProps = ToggleButtonProps & {
	TooltipProps: Omit<TooltipProps, "children">;
};

/**
 * This functional component is a workaround for an issue in mui where tooltips
 * on toggle buttons breaks button highlighting
 * https://github.com/mui/material-ui/issues/18091
 */
const TooltipToggleButton: FC<TooltipToggleButtonProps> = ({
	TooltipProps,
	...props
}) => {
	return props.disabled ? (
		<ToggleButton {...props} />
	) : (
		<Tooltip {...TooltipProps}>
			<ToggleButton {...props} />
		</Tooltip>
	);
};
export default EngineTileEvents;
