import { Box, Button } from "@mui/material";
import {
	useGetOrganizationUsers,
	useExistingOrganizationMutator,
} from "../../../api/organization";
import { useOkCancelDialog } from "../../../providers/ok-cancel-dialog/ok-cancel-dialog-provider";
import Tokens from "./tokens/tokens";
import Users from "./users/users";
import Engines from "./engines/engines";

const confirmDeleteTitle = "Are you sure you want to delete this organization?";
const confirmDeleteContent =
	"Deleting this organization will remove any data associated with it. Do you want to continue?";

type OrganizationExistingProps = {
	org: string;
	orgDelete: (orgId: string) => void; // changes selected org when deleted
};

/**
 * @param props the parameters as OrganizationExistingProps
 * @returns an existing organization controls for the manage page
 */
const OrganizationExisting = ({
	org,
	orgDelete,
}: OrganizationExistingProps) => {
	const existingOrganizationMutator = useExistingOrganizationMutator(org);
	const users = useGetOrganizationUsers(org);
	const { openDialog } = useOkCancelDialog();

	return (
		<Box display="flex" flexDirection="column" gap="2em" data-testid={org}>
			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column", md: "row" },
					width: "100%",
				}}
				gap="2em"
			>
				<Users org={org} />
				<Tokens org={org} />
			</Box>
			<Engines org={org} />
			{users.length === 1 && (
				<>
					<Button
						variant="contained"
						color="primary"
						data-testid="org-delete"
						onClick={async () => {
							const dialogResult = await openDialog(
								confirmDeleteTitle,
								confirmDeleteContent
							);
							if (dialogResult) {
								existingOrganizationMutator.deleteOrganization();
								orgDelete(org);
							}
						}}
					>
						Delete Organization
					</Button>
				</>
			)}
		</Box>
	);
};

export default OrganizationExisting;
