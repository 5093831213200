import {
	Cloud,
	ElectricCar,
	FolderSpecial,
	Folder,
	CallMade,
	RssFeed,
	QuestionMark,
	Route,
} from "@mui/icons-material";
import { Component } from "../../api/api.generated";

const componentTypeList = [
	"ENGINE",
	"LOCKER",
	"FOLDER",
	"CP",
	"ROUTE",
	"WEBSERVICE",
	"REST",
	"UNKNOWN",
] as const;

// The types of components in Rhapsody
export type ComponentType = typeof componentTypeList[number];

/**
 * @param component a rhapsody component from an API call
 * @returns the matching type from componentTypeList
 */
export const GetComponentType = (component: Component): ComponentType => {
	const componentType = component.type as ComponentType;
	if (componentTypeList.includes(componentType)) {
		return componentType;
	}
	return "UNKNOWN";
};

/**
 * @param type the component type
 * @returns the relevant display string
 */
export const GetDisplayString = (type: ComponentType) => {
	switch (type) {
		case "ENGINE":
			return "Engine";
		case "LOCKER":
			return "Locker";
		case "FOLDER":
			return "Folder";
		case "CP":
			return "Communication Point";
		case "ROUTE":
			return "Route";
		case "WEBSERVICE":
			return "Web Service";
		case "REST":
			return "REST Client";
		case "UNKNOWN":
		default:
			return "Other";
	}
};

/**
 * @param type the component type
 * @returns the relevant display icon
 */
export const GetComponentIcon = (type: ComponentType) => {
	switch (type) {
		case "ENGINE":
			return <ElectricCar />;
		case "LOCKER":
			return <FolderSpecial />;
		case "FOLDER":
			return <Folder />;
		case "CP":
			return <CallMade />;
		case "ROUTE":
			return <Route />;
		case "WEBSERVICE":
			return <RssFeed />;
		case "REST":
			return <Cloud />;
		case "UNKNOWN":
		default:
			return <QuestionMark />;
	}
};
