import { IconButton, Input, InputAdornment, Tooltip } from "@mui/material";
import { Visibility, VisibilityOff, ContentCopy } from "@mui/icons-material";
import { useState } from "react";
import { Token } from "../../../../api/api.generated";

// User can copy the token when visibility is toggled

const placeholderString = "●".repeat(36);
type TokenVisibilityProps = { org: string; token: Token };

/**
 * @param props the parameters as TokenVisibilityProps
 * @returns a token row on the manage page
 */
const TokenVisibility = (props: TokenVisibilityProps) => {
	const [showValue, setShowValue] = useState(false);
	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(true);
		navigator.clipboard.writeText(props.token.token);
		setTimeout(() => {
			setOpen(false);
		}, 2000);
	};
	return (
		<Input
			id="token-value"
			inputProps={{ "aria-label": `${props.token.name} value` }}
			type="text"
			value={showValue ? props.token.token : placeholderString}
			disabled={true}
			endAdornment={
				<InputAdornment position="end">
					<Tooltip
						PopperProps={{
							disablePortal: true,
						}}
						open={open}
						disableFocusListener
						disableHoverListener
						disableTouchListener
						placement="left"
						title="Copied!"
						sx={{ margin: 0 }}
					>
						<IconButton
							onClick={handleClick}
							data-testid={`copy-${props.token.name}`}
							aria-label="Copy token to clipboard"
							size="small"
						>
							<ContentCopy fontSize="small" />
						</IconButton>
					</Tooltip>
					<IconButton
						aria-label={`Toggle visibility for the token: ${props.token.name}`}
						size="small"
						onClick={(_) => setShowValue(!showValue)}
						onMouseDown={(e) => e.preventDefault()}
						edge="end"
					>
						{showValue ? <Visibility /> : <VisibilityOff />}
					</IconButton>
				</InputAdornment>
			}
		/>
	);
};

export default TokenVisibility;
