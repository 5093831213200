import { ExpandMore } from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useGetOrganizationTokens } from "../../../../api/organization";
import CreateToken from "./create-token";
import TokenRow from "./token-row";

type TokensProps = {
	org: string;
};

/**
 * @param props the props as TokensProps
 * @returns the tokens of an organization on the manage page
 */
const Tokens = (props: TokensProps) => {
	const tokens = useGetOrganizationTokens(props.org);

	return (
		<Box
			sx={{
				width: { xs: "100%", md: "50%" },
			}}
		>
			<Accordion defaultExpanded={true}>
				<AccordionSummary
					expandIcon={<ExpandMore />}
					data-testid="open-tokens-table"
				>
					<Typography variant="h4">Tokens</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ minHeight: "200px" }}>
					<CreateToken org={props.org} />
					{tokens.length === 0 ? (
						<Typography
							textAlign="center"
							paddingY="2.2em"
							fontStyle="italic"
							color="GrayText"
						>
							No Tokens
						</Typography>
					) : (
						<Box>
							<TableContainer sx={{ mt: 2 }}>
								<Table
									size="small"
									sx={{
										[`& .${tableCellClasses.root}`]: {
											borderBottom: "none",
										},
									}}
								>
									<TableHead
										sx={{
											display: {
												xs: "none",
												sm: "table-header-group",
											},
										}}
									>
										<TableRow>
											<TableCell
												sx={{
													display: {
														xs: "none",
														sm: "table-cell",
													},
													width: {
														xs: "100%",
														sm: "25%",
													},
												}}
											>
												<Typography variant="h6">
													Name
												</Typography>
											</TableCell>
											<TableCell
												sx={{
													display: {
														xs: "none",
														sm: "table-cell",
													},
													width: "70%",
												}}
											>
												<Typography variant="h6">
													Token
												</Typography>
											</TableCell>
											<TableCell sx={{ width: "5%" }} />
										</TableRow>
									</TableHead>
									<TableBody>
										{tokens.map((t) => (
											<TokenRow
												token={t}
												org={props.org}
												key={t.token}
											/>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					)}
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};

export default Tokens;
