import { Box, Typography } from "@mui/material";
import { Events as ApiEvents } from "../../api/api.generated";
import { IsMobile } from "../../utils/responsive-util";
import "./events.scss";
import {
	EventSeverity,
	MapOpenTelemetrySeverityToEventSeverity,
} from "../../rhapsody-stats";
import EventIcons from "./event-icons";

type EventsProps = { events: ApiEvents };

const secondaryText = (p: any) => <Typography color="textSecondary" {...p} />;

/**
 * @param param0 the parameters as EventsProps
 * @returns the event list for a given list of events
 */
const Events = ({ events }: EventsProps) => {
	const isMobile = IsMobile();

	const escalationEvents = events.filter(
		(event) =>
			MapOpenTelemetrySeverityToEventSeverity(event.severity) ===
			EventSeverity.ESCALATION
	);
	const alarmEvents = events.filter(
		(event) =>
			MapOpenTelemetrySeverityToEventSeverity(event.severity) ===
			EventSeverity.ALARM
	);
	const warningEvents = events.filter(
		(event) =>
			MapOpenTelemetrySeverityToEventSeverity(event.severity) ===
			EventSeverity.WARNING
	);

	let eventText = "";
	if (events.length > 0) {
		const highestEvent = events.reduce((prev, current) =>
			prev.severity > current.severity ? prev : current
		);
		// Hide the detail for mobile
		if (!isMobile) {
			eventText =
				MapOpenTelemetrySeverityToEventSeverity(
					highestEvent.severity
				) ?? "";
			if (events.length > 1) {
				eventText += ` + ${events.length - 1} others`;
			}
		}
	} else {
		eventText = "No Events";
	}

	return (
		<Box
			display="flex"
			alignItems="center"
			component={secondaryText}
			className="event-text"
			data-testid="event-text"
		>
			{eventText}
			{DisplayEvents(escalationEvents, EventSeverity.ESCALATION)}
			{DisplayEvents(alarmEvents, EventSeverity.ALARM)}
			{DisplayEvents(warningEvents, EventSeverity.WARNING)}
		</Box>
	);
};

const DisplayEvents = (events: ApiEvents, severity: EventSeverity) => {
	if (events.length === 0) {
		return <></>;
	}
	return (
		<>
			{<EventIcons severity={severity} />}
			<span data-testid={`events-count-${severity}`}>
				{events.length}
			</span>
		</>
	);
};

export default Events;
