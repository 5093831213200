import { ExpandMore, ChevronRight } from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { TreeNode, TreeTable, TreeState, Row } from "cp-react-tree-table";
import { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import Events from "../../components/events/events";
import { TreeComponent } from "./component-list";
import { GetComponentIcon } from "./helpers";
import "./tree-table.scss";

type TreeTableProps = {
	data: TreeComponent[];
	setSelected: (t: TreeComponent) => void;
};

/**
 * WIP: behind IsRhapsodyEnabled feature flag
 *
 * @param param0 the parameters as TreeTableProps
 * @returns a tree table of the rhapsody components
 */
const TreeTableWrapped = ({ data, setSelected }: TreeTableProps) => {
	const convertData = (
		treeComponents: TreeComponent[]
	): TreeNode<TreeComponent>[] =>
		treeComponents.map((c) => {
			return {
				data: c,
				children: convertData(c.children),
			};
		});

	const [treeValue, setTreeValue] = useState(
		TreeState.create<TreeComponent>([])
	);

	//TODO: CON-396 persist the expanded state
	useDeepCompareEffect(
		() => setTreeValue(TreeState.create(convertData(data))),
		[data]
	);

	return (
		<>
			<Box>
				<Button
					onClick={() => setTreeValue(TreeState.expandAll(treeValue))}
				>
					Expand all
				</Button>
				<Button
					onClick={() =>
						setTreeValue(TreeState.collapseAll(treeValue))
					}
				>
					Collapse all
				</Button>
			</Box>
			<TreeTable
				value={treeValue}
				onChange={(newValue) => setTreeValue(newValue)}
				height={900}
				headerHeight={32}
			>
				<TreeTable.Column
					basis="180px"
					grow={1}
					renderCell={(r: Row<TreeComponent>) =>
						renderIndexCell(r, setSelected)
					}
					renderHeaderCell={() => <Typography>Name</Typography>}
				/>
				<TreeTable.Column
					basis="180px"
					grow={1}
					renderCell={(r: Row<TreeComponent>) => (
						<Events events={r.data.events} />
					)}
					renderHeaderCell={() => <Typography>Alerts</Typography>}
				/>
			</TreeTable>
		</>
	);
};

const renderIndexCell = (
	row: Row<TreeComponent>,
	setSelected: (t: TreeComponent) => void
) => {
	// Render the icon later as transparent so we have consistent spacing
	const color = row.metadata.hasChildren ? "black" : "transparent";

	return (
		<Stack
			direction="row"
			style={{
				paddingLeft: row.metadata.depth * 15 + "px",
				alignItems: "center",
			}}
			onClick={() => setSelected(row.data)}
		>
			<IconButton
				disabled={!row.metadata.hasChildren}
				onClick={(e) => {
					e.stopPropagation();
					row.toggleChildren();
				}}
			>
				{row.$state.isExpanded ? (
					<ExpandMore htmlColor={color} />
				) : (
					<ChevronRight htmlColor={color} />
				)}
			</IconButton>
			{GetComponentIcon(row.data.type)}
			<Typography>{row.data.name}</Typography>
		</Stack>
	);
};

export default TreeTableWrapped;
