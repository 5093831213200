import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	IconButton,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableRow,
	Typography,
} from "@mui/material";
import { ExpandMore, DeleteOutline } from "@mui/icons-material";
import {
	useGetOrganizationUsers,
	useExistingOrganizationMutator,
} from "../../../../api/organization";
import { useGetCurrentUser, GetDisplayValue } from "../../../../api/user";
import { useOkCancelDialog } from "../../../../providers/ok-cancel-dialog/ok-cancel-dialog-provider";
import AddUser from "./add-user";

// The title of the dialog when you are deleting yourself
export const deleteSelfTitle =
	"Are you sure you want to leave this organization?";
// The content of the dialog when you are deleting yourself
export const deleteSelfContent =
	"This will remove you from this organization. In order to view these engines again you will have to be added by another user from this organization.";

// The title of the dialog when you are deleting another user
export const deleteUserTitle =
	"Are you sure you want to delete this user from this organization?";
// The content of the dialog when you are deleting another user
export const deleteUserContent =
	"Deleting this user will prevent them from viewing engines in this organization. They will need to be added back into this organization to view these engines again.";

type UsersProps = {
	org: string;
};

/**
 * @param props the parameters as UsersProps
 * @returns the existing users for an organization on the manage page
 */
const Users = (props: UsersProps) => {
	const { user: currentUser } = useGetCurrentUser();

	const existingOrganizationMutator = useExistingOrganizationMutator(
		props.org
	);

	const users = useGetOrganizationUsers(props.org);

	const { openDialog } = useOkCancelDialog();

	return (
		<Box
			sx={{
				width: { xs: "100%", md: "50%" },
			}}
		>
			<Accordion defaultExpanded={true}>
				<AccordionSummary
					expandIcon={<ExpandMore />}
					data-testid="open-users-table"
				>
					<Typography variant="h4">Users</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ minHeight: "200px" }}>
					<AddUser org={props.org} />
					<TableContainer>
						<Table
							size="small"
							sx={{
								[`& .${tableCellClasses.root}`]: {
									borderBottom: "none",
								},
							}}
						>
							<TableBody>
								{users.map((u) => (
									<TableRow
										key={u.email ?? ""}
										data-testid={"user" + u.email}
									>
										<TableCell data-testid="user-email">
											{GetDisplayValue(u)}
										</TableCell>
										<TableCell align="right">
											{users.length > 1 && (
												<IconButton
													onClick={async () => {
														let title: string,
															content: string;
														if (
															u.issuer ===
																currentUser?.issuer &&
															u.subject ===
																currentUser?.subject
														) {
															title =
																deleteSelfTitle;
															content =
																deleteSelfContent;
														} else {
															title =
																deleteUserTitle;
															content =
																deleteUserContent;
														}

														const dialogResult =
															await openDialog(
																title,
																content
															);
														if (dialogResult) {
															existingOrganizationMutator.deleteUser(
																u.issuer,
																u.subject
															);
														}
													}}
													color="primary"
													size="large"
													data-testid="del-user"
													aria-label={`Delete the user: ${u.email}`}
												>
													<DeleteOutline fontSize="inherit" />
												</IconButton>
											)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};

export default Users;
