import {
	AccountCircleOutlined,
	Bolt,
	Dashboard,
	ExitToApp,
	Menu as MenuIcon,
	Settings,
} from "@mui/icons-material";
import {
	AppBar,
	Box,
	Drawer,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Toolbar,
} from "@mui/material";
import { useState } from "react";
import { GetDisplayValue } from "../../api/user";
import { ReactComponent as Logo } from "../../assets/rhapsody-header.svg";
import { GetBaseUrl } from "../../api/api.customBaseQuery";
import { User } from "../../api/api.generated";
import { IsRhapsodyEnabled } from "../../utils/feature-flags";

type HeaderProps = {
	currentUser?: User;
};

/**
 * @param param0 the parameters as HeaderProps
 * @returns the page header
 */
const Header = ({ currentUser }: HeaderProps) => {
	const displayName = currentUser ? GetDisplayValue(currentUser) : null;

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [drawerOpen, setDrawerOpen] = useState(false);

	const logoutUrl = GetBaseUrl() + "/auth/logout";

	return (
		<Box flexGrow="1">
			<AppBar position="sticky">
				<Toolbar>
					{displayName && (
						<IconButton
							size="large"
							edge="start"
							sx={{ color: "white", mr: { md: 2 } }}
							onClick={() => setDrawerOpen(true)}
							aria-label="Menu"
						>
							<MenuIcon fontSize="inherit" />
						</IconButton>
					)}
					<Box
						alignItems="center"
						display="flex"
						flexGrow="1"
						component={Link}
						href="/"
						underline="hover"
						data-testid="home-link"
						gap="0.5em"
						sx={{ justifyContent: { xs: "center", md: "normal" } }}
					>
						<Box
							aria-label="Rhapsody Console"
							component={Logo}
							sx={{
								width: { xs: "15em", sm: "20em" },
							}}
						/>
					</Box>
					{displayName && (
						<div>
							<IconButton
								size="large"
								edge="end"
								id="user-button"
								aria-label="user-menu"
								aria-haspopup="true"
								aria-expanded={open ? "true" : undefined}
								onClick={handleClick}
								data-testid="user-menu"
								sx={{ color: "white" }}
							>
								<AccountCircleOutlined fontSize="inherit" />
							</IconButton>
							<Menu
								id="user-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								MenuListProps={{
									"aria-labelledby": "user-button",
								}}
							>
								<MenuItem
									disabled
									aria-label={`Logged in as ${displayName}`}
									data-testid="display-name"
									sx={{ py: 0 }}
								>
									<ListItemText>{displayName}</ListItemText>
								</MenuItem>
								<MenuItem
									component={Link}
									href="/manage"
									underline="hover"
									data-testid="menu-item-manage"
									aria-label="Manage"
								>
									<ListItemIcon>
										<Settings fontSize="small" />
									</ListItemIcon>
									<ListItemText>Manage</ListItemText>
								</MenuItem>
								<MenuItem
									component={Link}
									href={logoutUrl}
									underline="hover"
									data-testid="menu-item-logout"
									aria-label="Logout"
								>
									<ListItemIcon>
										<ExitToApp fontSize="small" />
									</ListItemIcon>
									<ListItemText>Logout</ListItemText>
								</MenuItem>
							</Menu>
						</div>
					)}
				</Toolbar>
			</AppBar>
			{displayName && (
				<Drawer
					anchor="left"
					open={drawerOpen}
					onClose={() => setDrawerOpen(false)}
				>
					<List>
						<ListItem component={Link} href="/">
							<ListItemButton>
								<ListItemIcon>
									<Dashboard />
								</ListItemIcon>
								<ListItemText>Cards</ListItemText>
							</ListItemButton>
						</ListItem>
						{IsRhapsodyEnabled && (
							<ListItem component={Link} href="/rhapsody">
								<ListItemButton>
									<ListItemIcon>
										<Bolt />
									</ListItemIcon>
									<ListItemText>Rhapsody</ListItemText>
								</ListItemButton>
							</ListItem>
						)}
					</List>
				</Drawer>
			)}
		</Box>
	);
};

export default Header;
