import {
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { useGetAllEngines } from "../../../../../api/engine";
import EngineRow from "./engine-row";

type EngineTableProps = {
	org: string;
};

/**
 * @param props the parameters as EngineProps
 * @returns the engines on the manage page
 */
const EngineTable = (props: EngineTableProps) => {
	const { engines, apiStatus } = useGetAllEngines((data, apiStatus) => {
		if (apiStatus.isSuccess && data) {
			return { engines: data, apiStatus };
		}
		return { engines: [], apiStatus };
	}, props.org);

	return (
		<TableContainer>
			<Table
				data-testid="engines"
				size="small"
				sx={{
					[`& .${tableCellClasses.root}`]: {
						borderBottom: "none",
					},
				}}
			>
				<TableHead>
					<TableRow>
						<TableCell sx={{ width: "28%" }}>
							<Typography variant="h6">Name</Typography>
						</TableCell>
						<TableCell sx={{ width: "12%" }}>
							<Typography variant="h6">Version</Typography>
						</TableCell>
						<TableCell sx={{ width: "15%" }}>
							<Typography variant="h6">Token</Typography>
						</TableCell>
						<TableCell sx={{ width: "15%" }}>
							<Typography variant="h6">Tags</Typography>
						</TableCell>
						<TableCell sx={{ width: "20%" }}>
							<Typography variant="h6">Last updated</Typography>
						</TableCell>
						<TableCell sx={{ width: "10%" }} />
					</TableRow>
				</TableHead>
				<TableBody
					data-testid="engines-list"
					data-test-is-fetching={apiStatus.isFetching}
				>
					{engines.map((e) => (
						<EngineRow key={e.id} engine={e} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default EngineTable;
