import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQueryWithReauth } from "./api.customBaseQuery";
export const api = createApi({
  baseQuery: customFetchBaseQueryWithReauth,
  tagTypes: [],
  endpoints: (build) => ({
    healthCheck: build.query<HealthCheckApiResponse, HealthCheckApiArg>({
      query: () => ({ url: `/` }),
    }),
    getEngine: build.query<GetEngineApiResponse, GetEngineApiArg>({
      query: (queryArg) => ({
        url: `/engine`,
        params: { tag: queryArg.tag, organizationId: queryArg.organizationId },
      }),
    }),
    deleteEngine: build.mutation<DeleteEngineApiResponse, DeleteEngineApiArg>({
      query: (queryArg) => ({
        url: `/engine/${queryArg.engine}`,
        method: "DELETE",
      }),
    }),
    getUptime: build.query<GetUptimeApiResponse, GetUptimeApiArg>({
      query: (queryArg) => ({
        url: `/engine/${queryArg.engine}/uptime`,
        params: { from: queryArg["from"], duration: queryArg.duration },
      }),
    }),
    getRestarts: build.query<GetRestartsApiResponse, GetRestartsApiArg>({
      query: (queryArg) => ({
        url: `/engine/${queryArg.engine}/restarts`,
        params: { from: queryArg["from"], duration: queryArg.duration },
      }),
    }),
    getCurrentStatistics: build.query<
      GetCurrentStatisticsApiResponse,
      GetCurrentStatisticsApiArg
    >({
      query: (queryArg) => ({
        url: `/engine/${queryArg.engine}/currentStats`,
        params: {
          measures: queryArg.measures,
          components: queryArg.components,
          maxAge: queryArg.maxAge,
        },
      }),
    }),
    getComponents: build.query<GetComponentsApiResponse, GetComponentsApiArg>({
      query: (queryArg) => ({ url: `/engine/${queryArg.engine}/components` }),
    }),
    getStatistics: build.query<GetStatisticsApiResponse, GetStatisticsApiArg>({
      query: (queryArg) => ({
        url: `/engine/${queryArg.engine}/${queryArg.component}/stats`,
        params: {
          measures: queryArg.measures,
          from: queryArg["from"],
          duration: queryArg.duration,
          per: queryArg.per,
          interpolationResolution: queryArg.interpolationResolution,
        },
      }),
    }),
    getLatestEvents: build.query<
      GetLatestEventsApiResponse,
      GetLatestEventsApiArg
    >({
      query: (queryArg) => ({
        url: `/engine/${queryArg.engine}/latestEvents`,
        params: {
          existingFields: queryArg.existingFields,
          matchingFields: queryArg.matchingFields,
        },
      }),
    }),
    getEngineTags: build.query<GetEngineTagsApiResponse, GetEngineTagsApiArg>({
      query: (queryArg) => ({ url: `/engine/${queryArg.engine}/tag` }),
    }),
    putTag: build.mutation<PutTagApiResponse, PutTagApiArg>({
      query: (queryArg) => ({
        url: `/engine/${queryArg.engine}/tag/${queryArg.tag}`,
        method: "PUT",
      }),
    }),
    deleteTag: build.mutation<DeleteTagApiResponse, DeleteTagApiArg>({
      query: (queryArg) => ({
        url: `/engine/${queryArg.engine}/tag/${queryArg.tag}`,
        method: "DELETE",
      }),
    }),
    getTag: build.query<GetTagApiResponse, GetTagApiArg>({
      query: () => ({ url: `/tag` }),
    }),
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: () => ({ url: `/user/me` }),
    }),
    getUserByEmail: build.query<
      GetUserByEmailApiResponse,
      GetUserByEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/user`,
        params: { email: queryArg.email },
      }),
    }),
    getUserOrganizations: build.query<
      GetUserOrganizationsApiResponse,
      GetUserOrganizationsApiArg
    >({
      query: () => ({ url: `/organization` }),
    }),
    postOrganization: build.mutation<
      PostOrganizationApiResponse,
      PostOrganizationApiArg
    >({
      query: (queryArg) => ({
        url: `/organization`,
        method: "POST",
        body: queryArg.organization,
      }),
    }),
    getOrganization: build.query<
      GetOrganizationApiResponse,
      GetOrganizationApiArg
    >({
      query: (queryArg) => ({
        url: `/organization/${queryArg.organizationId}`,
      }),
    }),
    deleteOrganization: build.mutation<
      DeleteOrganizationApiResponse,
      DeleteOrganizationApiArg
    >({
      query: (queryArg) => ({
        url: `/organization/${queryArg.organizationId}`,
        method: "DELETE",
      }),
    }),
    getOrganizationUsers: build.query<
      GetOrganizationUsersApiResponse,
      GetOrganizationUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/organization/${queryArg.organizationId}/user`,
      }),
    }),
    putUserOrganization: build.mutation<
      PutUserOrganizationApiResponse,
      PutUserOrganizationApiArg
    >({
      query: (queryArg) => ({
        url: `/organization/${queryArg.organizationId}/user/${queryArg.userIss}/${queryArg.userSub}`,
        method: "PUT",
      }),
    }),
    deleteUserOrganization: build.mutation<
      DeleteUserOrganizationApiResponse,
      DeleteUserOrganizationApiArg
    >({
      query: (queryArg) => ({
        url: `/organization/${queryArg.organizationId}/user/${queryArg.userIss}/${queryArg.userSub}`,
        method: "DELETE",
      }),
    }),
    getToken: build.query<GetTokenApiResponse, GetTokenApiArg>({
      query: (queryArg) => ({
        url: `/organization/${queryArg.organizationId}/token`,
      }),
    }),
    postToken: build.mutation<PostTokenApiResponse, PostTokenApiArg>({
      query: (queryArg) => ({
        url: `/organization/${queryArg.organizationId}/token/${queryArg.name}`,
        method: "POST",
      }),
    }),
    deleteToken: build.mutation<DeleteTokenApiResponse, DeleteTokenApiArg>({
      query: (queryArg) => ({
        url: `/organization/${queryArg.organizationId}/token/${queryArg.name}`,
        method: "DELETE",
      }),
    }),
    authLogin: build.query<AuthLoginApiResponse, AuthLoginApiArg>({
      query: (queryArg) => ({
        url: `/auth/login`,
        params: { redirect: queryArg.redirect },
      }),
    }),
    authCallback: build.query<AuthCallbackApiResponse, AuthCallbackApiArg>({
      query: (queryArg) => ({
        url: `/auth/callback`,
        params: { code: queryArg.code },
      }),
    }),
    authRefresh: build.query<AuthRefreshApiResponse, AuthRefreshApiArg>({
      query: () => ({ url: `/auth/refresh` }),
    }),
    authLogout: build.query<AuthLogoutApiResponse, AuthLogoutApiArg>({
      query: () => ({ url: `/auth/logout` }),
    }),
  }),
});
export type HealthCheckApiResponse = unknown;
export type HealthCheckApiArg = void;
export type GetEngineApiResponse = /** status 200 OK */ Engines;
export type GetEngineApiArg = {
  /** Comma separated list of tags to filter engines.  Only engines that match these tags will be returned. */
  tag?: string[];
  /** Organization to limit by. Only engines in this organization will be returned. */
  organizationId?: string;
};
export type DeleteEngineApiResponse = unknown;
export type DeleteEngineApiArg = {
  /** Engine unique ID. */
  engine: string;
};
export type GetUptimeApiResponse = /** status 200 OK */ Uptime;
export type GetUptimeApiArg = {
  /** Engine unique ID. */
  engine: string;
  /** Start of the uptime interval (Unix timestamp).  Default is now() - duration. */
  from?: number;
  /** Duration of the uptime interval in seconds. */
  duration: number;
};
export type GetRestartsApiResponse = /** status 200 OK */ Restarts;
export type GetRestartsApiArg = {
  /** Engine unique ID. */
  engine: string;
  /** Start of query interval.  Default is now() - duration. */
  from?: number;
  /** Duration of the query interval in seconds. */
  duration: number;
};
export type GetCurrentStatisticsApiResponse = /** status 200 OK */ Statistics;
export type GetCurrentStatisticsApiArg = {
  /** Engine unique ID. */
  engine: string;
  /** The names of the measures to query. */
  measures: string[];
  /** The component ids to query */
  components: string[];
  /** Maximum age of statistics record to return in seconds.  If there is no record younger than maxAge seconds then no results are returned. */
  maxAge?: number;
};
export type GetComponentsApiResponse = /** status 200 OK */ Components;
export type GetComponentsApiArg = {
  /** Engine unique ID. */
  engine: string;
};
export type GetStatisticsApiResponse = /** status 200 OK */ Statistics;
export type GetStatisticsApiArg = {
  /** Engine unique ID. */
  engine: string;
  /** Component to query. */
  component: string;
  /** The names of the measures to query. */
  measures: string[];
  /** Start of the query time range (UNIX timestamp).  Default is now() - duration. */
  from?: number;
  /** Duration of the query time range in seconds. */
  duration: number;
  /** Time interval over which to compute rate of change in seconds.  E.g. to get message throughput per minute, query for the "tr", "ts", "tp", and "tf" measures and set "per" to 60.
   */
  per?: number;
  /** The number of values to interpolate based on the statistics. */
  interpolationResolution?: number;
};
export type GetLatestEventsApiResponse = /** status 200 OK */ Events;
export type GetLatestEventsApiArg = {
  /** Engine unique ID. */
  engine: string;
  /** Body fields that must exist on the event. */
  existingFields?: string[];
  /** Body fields that must match on the event, in the format [key, value, ...]. */
  matchingFields?: string[];
};
export type GetEngineTagsApiResponse = /** status 200 OK */ string[];
export type GetEngineTagsApiArg = {
  /** Engine unique ID. */
  engine: string;
};
export type PutTagApiResponse = unknown;
export type PutTagApiArg = {
  /** Engine unique ID. */
  engine: string;
  /** Tag to add. */
  tag: string;
};
export type DeleteTagApiResponse = unknown;
export type DeleteTagApiArg = {
  /** Engine unique ID. */
  engine: string;
  /** Tag to delete. */
  tag: string;
};
export type GetTagApiResponse = /** status 200 OK */ Tags;
export type GetTagApiArg = void;
export type GetUserApiResponse = /** status 200 OK */ User;
export type GetUserApiArg = void;
export type GetUserByEmailApiResponse = /** status 200 OK */ User[];
export type GetUserByEmailApiArg = {
  /** The users email. */
  email: string;
};
export type GetUserOrganizationsApiResponse = /** status 200 OK */ string[];
export type GetUserOrganizationsApiArg = void;
export type PostOrganizationApiResponse = unknown;
export type PostOrganizationApiArg = {
  organization: Organization;
};
export type GetOrganizationApiResponse = /** status 200 OK */ Organization;
export type GetOrganizationApiArg = {
  /** Organization unique ID. */
  organizationId: string;
};
export type DeleteOrganizationApiResponse = unknown;
export type DeleteOrganizationApiArg = {
  /** Organization unique ID. */
  organizationId: string;
};
export type GetOrganizationUsersApiResponse = /** status 200 OK */ User[];
export type GetOrganizationUsersApiArg = {
  /** Organization unique ID. */
  organizationId: string;
};
export type PutUserOrganizationApiResponse = unknown;
export type PutUserOrganizationApiArg = {
  /** Organization unique ID. */
  organizationId: string;
  /** User ISS identifier (users can get their own ISS from /user/me). */
  userIss: string;
  /** User SUB identifier (users can get their own SUB from /user/me). */
  userSub: string;
};
export type DeleteUserOrganizationApiResponse = unknown;
export type DeleteUserOrganizationApiArg = {
  /** Organization unique ID */
  organizationId: string;
  /** User ISS identifier (users can get their own ISS from /user/me) */
  userIss: string;
  /** User SUB identifier (users can get their own SUB from /user/me) */
  userSub: string;
};
export type GetTokenApiResponse = /** status 200 OK */ Tokens;
export type GetTokenApiArg = {
  /** Organization unique ID. */
  organizationId: string;
};
export type PostTokenApiResponse = unknown;
export type PostTokenApiArg = {
  /** Organization unique ID. */
  organizationId: string;
  /** Human readable name for the new token. */
  name: string;
};
export type DeleteTokenApiResponse = unknown;
export type DeleteTokenApiArg = {
  /** Organization unique ID. */
  organizationId: string;
  /** Human readable name for the token. */
  name: string;
};
export type AuthLoginApiResponse = unknown;
export type AuthLoginApiArg = {
  /** URL to redirect to after login. */
  redirect: string;
};
export type AuthCallbackApiResponse = unknown;
export type AuthCallbackApiArg = {
  /** Cognito code. */
  code: string;
};
export type AuthRefreshApiResponse = unknown;
export type AuthRefreshApiArg = void;
export type AuthLogoutApiResponse = unknown;
export type AuthLogoutApiArg = void;
export type Engine = {
  id: string;
  name: string;
  link: string;
  version: string;
  lastUpdate?: string | null;
  tags: string[];
  tokenName?: string | null;
};
export type Engines = Engine[];
export type Uptime = {
  downtime: number;
  lastUpdate?: string | null;
};
export type Restart = {
  time?: string;
  duration?: number;
  is_maintenance?: boolean;
};
export type Restarts = Restart[];
export type Statistics = {
  data: {
    n: string;
    c?: string;
    a?: {
      [key: string]: string;
    } | null;
    v: number[][];
  }[];
};
export type Component = {
  uuid: string;
  id: string;
  name: string;
  path: string;
  type: string;
  subtype?: string;
  attributes?: {
    [key: string]: any;
  };
};
export type Components = Component[];
export type Event = {
  timestamp: string;
  eventType: string;
  severity: number;
  component?: string;
  group?: string;
  body?: {
    [key: string]: any;
  };
};
export type Events = Event[];
export type Tags = string[];
export type User = {
  issuer: string;
  subject: string;
  name?: string | null;
  email?: string | null;
};
export type Organization = {
  name: string;
};
export type Token = {
  token: string;
  name: string;
  engines: string[];
};
export type Tokens = Token[];
export const {
  useHealthCheckQuery,
  useGetEngineQuery,
  useDeleteEngineMutation,
  useGetUptimeQuery,
  useGetRestartsQuery,
  useGetCurrentStatisticsQuery,
  useGetComponentsQuery,
  useGetStatisticsQuery,
  useGetLatestEventsQuery,
  useGetEngineTagsQuery,
  usePutTagMutation,
  useDeleteTagMutation,
  useGetTagQuery,
  useGetUserQuery,
  useGetUserByEmailQuery,
  useGetUserOrganizationsQuery,
  usePostOrganizationMutation,
  useGetOrganizationQuery,
  useDeleteOrganizationMutation,
  useGetOrganizationUsersQuery,
  usePutUserOrganizationMutation,
  useDeleteUserOrganizationMutation,
  useGetTokenQuery,
  usePostTokenMutation,
  useDeleteTokenMutation,
  useAuthLoginQuery,
  useAuthCallbackQuery,
  useAuthRefreshQuery,
  useAuthLogoutQuery,
} = api;
