import {
	Box,
	Button,
	Container,
	Typography,
	useTheme,
	useMediaQuery,
} from "@mui/material";
import { ReactComponent as Logo } from "../../assets/rhapsody.svg";
import { ReactComponent as Graphic } from "../../assets/rhapsody-graphic.svg";
import { ReactComponent as GraphicMobile } from "../../assets/rhapsody-graphic-mobile.svg";
import { ReactComponent as Squiggle } from "../../assets/squiggle.svg";
import { GetBaseUrl } from "../../api/api.customBaseQuery";

/**
 * @returns the login screen
 */
const Login = () => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));
	const loginClicked = () => {
		window.location.assign(
			GetBaseUrl() + "/auth/login?redirect=" + window.location.href
		);
	};

	return (
		<>
			{mobile ? (
				// mobile view
				<>
					<Box
						data-testid="mobile-graphic"
						component={GraphicMobile}
						sx={{
							position: "fixed",
							left: "50%",
							top: "45%",
							transform: "translate(-50%, -50%)",
							width: "100vw",
							height: "auto",
						}}
					/>
					<Button
						variant="contained"
						data-testid="mobile-login-button"
						id="mobile-login-button"
						onClick={loginClicked}
						sx={{
							position: "fixed",
							left: "50%",
							top: "43%",
							width: "70%",
							transform: "translate(-50%, -50%)",
							boxShadow: "3px 4px 4px 1px rgba(0, 0, 0, 0.2)",
						}}
					>
						SIGN IN
					</Button>
				</>
			) : (
				// tablet/desktop view
				<Container
					maxWidth="xl"
					sx={{
						width: "70%",
						display: "flex",
						flexDirection: "row",
						marginTop: "26vh",
						gap: { sm: "0", md: "3em", lg: "5em" },
					}}
				>
					<Box
						data-testid="squiggle"
						component={Squiggle}
						sx={{
							position: "absolute",
							left: "0",
							bottom: "0",
							zIndex: "-1",
							width: "100vw",
							height: "auto",
							overflow: "hidden",
						}}
					/>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							gap: {
								sm: "4em",
								md: "6em",
								lg: "8em",
								xl: "10em",
							},
						}}
					>
						<Box
							data-testid="logo"
							aria-label="rhapsody console"
							component={Logo}
							sx={{
								width: "90%",
								height: "auto",
							}}
						/>
						<Box
							data-testid="graphic"
							aria-label="rhapsody console"
							component={Graphic}
							sx={{
								display: { sm: "none " },
								height: "auto",
							}}
						/>
						<Button
							variant="contained"
							data-testid="login-button"
							id="login-button"
							onClick={loginClicked}
							sx={{
								alignItems: "center",
								width: "60%",
								height: { sm: "2em", lg: "2.5em", xl: "3em" },
							}}
						>
							<Typography fontSize={{ sm: 12, lg: 16, xl: 20 }}>
								SIGN IN
							</Typography>
						</Button>
					</Box>
					<Box
						aria-label="rhapsody console"
						component={Graphic}
						sx={{
							height: "auto",
						}}
					/>
				</Container>
			)}
		</>
	);
};

export default Login;
