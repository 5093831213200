import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { FormEvent, useState } from "react";
import { User } from "../../../../api/api.generated";
import {
	useGetOrganizationUsers,
	useExistingOrganizationMutator,
} from "../../../../api/organization";
import { useGetUserByEmail } from "../../../../api/user";

type AddUserProps = {
	org: string;
};

/**
 * @param props the parameters as AddUserProps
 * @returns the add user controls on the manage page
 */
const AddUser = (props: AddUserProps) => {
	const [value, setValue] = useState<User | null>(null);
	const [inputValue, setInputValue] = useState("");

	const users = useGetUserByEmail(inputValue);
	const usersToExclude = useGetOrganizationUsers(props.org);
	const usersAvailable = users.filter(
		(x) =>
			!usersToExclude.find(
				(y) => x.subject === y.subject && x.issuer === y.issuer
			)
	);

	const [addEnabled, setAddEnabled] = useState<boolean>(false);
	const userMutator = useExistingOrganizationMutator(props.org);
	const addUserClicked = (e: FormEvent) => {
		e.preventDefault();
		if (value) {
			userMutator.putUser(value.issuer, value.subject);
			setValue(null);
			setInputValue("");
			setAddEnabled(false);
		}
	};

	return (
		<Box
			display="flex"
			alignItems="center"
			gap="1em"
			component="form"
			onSubmit={addUserClicked}
			paddingBottom="0.6rem"
		>
			<Box sx={{ width: "100%", flexGrow: { xs: 1, md: 0 } }}>
				<Autocomplete
					size="small"
					sx={{ width: "100%" }}
					getOptionLabel={(option) => option.email ?? "Unknown email"}
					options={usersAvailable}
					filterOptions={(x) => x} // Disable filtering inside the autocomplete as it is done in the query params
					value={value}
					onChange={(_, newValue: User | null) => {
						setValue(newValue);
						setAddEnabled(newValue !== null);
					}}
					onInputChange={(_, newInputValue) => {
						setInputValue(newInputValue);
					}}
					renderInput={(params) => (
						<TextField {...params} label="Email" />
					)}
					data-testid="add-email"
					isOptionEqualToValue={(a: User, b: User) => {
						return a.issuer === b.issuer && a.subject === b.subject;
					}}
				/>
			</Box>
			<Button
				variant="contained"
				color="primary"
				disabled={!addEnabled}
				type="submit"
				data-testid="add-user-button"
				sx={{ width: "6rem" }}
			>
				ADD
			</Button>
		</Box>
	);
};

export default AddUser;
