import {
	Container,
	Box,
	Select,
	MenuItem,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	Toolbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useGetOrganizations } from "../../api/organization";
import OrganizationExisting from "./organization-existing/organization-existing";
import OrganizationExistingHeader from "./organization-existing-header";
import OrganizationCreate from "./organization-create";
import Loading, { SpinnerSize } from "../../components/loaders/loader-spinner";
import AlertBanner, {
	AlertType,
} from "../../components/alert-banners/alert-banner";
import {
	ErrorBoundary,
	FallbackType,
} from "../../components/error-handling/error-boundary";
import OrgPanel from "../../components/org-panel";
import { ReactComponent as AddOrganizationIcon } from "../../assets/icons/add-organization.svg";

const NO_ORGANIZATION = "";

/**
 * @returns the manage page
 */
const Manage = () => {
	const [createDialogOpen, setCreateDialogOpen] = useState(false);
	const { organizations, apiStatus } = useGetOrganizations();
	const [organizationList, setOrganizationList] = useState(organizations);
	const [selectedOrg, setSelectedOrg] = useState(
		organizations[0] ?? NO_ORGANIZATION
	);

	useDeepCompareEffect(() => {
		setOrganizationList(organizations);
		setSelectedOrg((prevOrg) => {
			if (!prevOrg || prevOrg === NO_ORGANIZATION) {
				return organizations.length > 0
					? organizations[0]
					: NO_ORGANIZATION;
			}
			return prevOrg;
		});
	}, [organizations]);

	const handleCreateDialogOpen = () => {
		setCreateDialogOpen(true);
	};

	const handleCreateDialogClose = () => {
		setCreateDialogOpen(false);
	};

	const createOrg = (orgId: string) => {
		const organizationListCopy = organizationList.slice();
		organizationListCopy.push(orgId);
		setOrganizationList(organizationListCopy);
		setSelectedOrg(orgId);
		handleCreateDialogClose();
	};

	const deleteOrg = (orgId: string) => {
		const organizationListCopy = organizationList.slice();
		const index = organizationListCopy.indexOf(orgId);
		organizationListCopy.splice(index, 1);
		setOrganizationList(organizationListCopy);
		if (index === 0) {
			setSelectedOrg(
				organizationListCopy.length < 1
					? NO_ORGANIZATION
					: organizationListCopy[0]
			);
		} else {
			setSelectedOrg(organizationListCopy[index - 1]);
		}
	};

	if (apiStatus.isError) {
		return (
			<AlertBanner
				alertType={AlertType.Error}
				header="Error"
				message="Error loading the organizations, try reloading the page."
				details={JSON.stringify(apiStatus.error)}
			/>
		);
	}

	if (!apiStatus.isSuccess || apiStatus.isLoading) {
		return <Loading spinnerSize={SpinnerSize.large} />;
	}

	return (
		<Container data-testid="manage-page">
			<Box
				display="flex"
				flexDirection="row"
				justifyContent="center"
				alignContent="center"
				paddingTop="2rem"
			>
				<Select
					SelectDisplayProps={{ "aria-label": "select organization" }}
					data-testid="org-select"
					fullWidth
					displayEmpty
					value={selectedOrg}
					onChange={(event) => setSelectedOrg(event.target.value)}
					sx={{
						maxWidth: 300,
						fontWeight: "bold",
						fontSize: "1rem",
						backgroundColor: "white",
						boxShadow: "1",
						".MuiOutlinedInput-notchedOutline": { border: 0 },
						ml: { sm: 9 },
					}}
					disabled={selectedOrg === NO_ORGANIZATION}
					renderValue={
						selectedOrg === NO_ORGANIZATION
							? () => "No Organizations"
							: undefined
					}
				>
					{organizationList.map((org) => (
						<MenuItem
							value={org}
							key={org}
							data-testid={`select-${org}`}
						>
							<OrganizationExistingHeader org={org} />
						</MenuItem>
					))}
				</Select>
				<IconButton
					data-testid="create-organization-button"
					onClick={handleCreateDialogOpen}
					sx={{ ml: 1, p: 1.7 }}
					aria-label="Create organization"
				>
					<AddOrganizationIcon width="2.5rem" height="2.5rem" />
				</IconButton>
			</Box>
			<Dialog
				open={createDialogOpen}
				onClose={handleCreateDialogClose}
				fullWidth
			>
				<Toolbar
					style={{
						minHeight: 0,
						justifyContent: "flex-end",
						alignItems: "flex-start",
						padding: "10px 10px 0px 0px",
					}}
				>
					<IconButton
						edge="start"
						color="inherit"
						onClick={handleCreateDialogClose}
						aria-label="close"
					>
						<CloseIcon data-testid="close-create-org" />
					</IconButton>
				</Toolbar>
				<DialogTitle
					sx={{
						fontSize: { xs: "1.3rem", sm: "1.5rem" },
						py: "0",
						textAlign: "center",
					}}
				>
					Create Organization
				</DialogTitle>
				<DialogContent>
					<OrganizationCreate createOrg={createOrg} />
				</DialogContent>
			</Dialog>

			{organizationList.map((org) => (
				<OrgPanel value={org} selectedOrg={selectedOrg} key={org}>
					<ErrorBoundary
						scope="Manage existing organization"
						fallback={FallbackType.AlertMessage}
						message={`Error loading ${org}`}
						styles={{ padding: 0 }}
					>
						<OrganizationExisting org={org} orgDelete={deleteOrg} />
					</ErrorBoundary>
				</OrgPanel>
			))}
		</Container>
	);
};

export default Manage;
