import { Typography } from "@mui/material";
import { ReactComponent as UptimeIcon } from "../../../assets/icons/uptime.svg";
import { useGetUptime } from "../../../api/engine";
import { TruncateDecimal } from "../../../utils/number-util";

const secondsInMonth = 30 * 24 * 60 * 60;

type UptimeProps = {
	id: string;
};

/**
 * @param param0 the parameters as UptimeProps
 * @returns an engine uptime element
 */
const EngineUptime = ({ id }: UptimeProps) => {
	const percent = (n: number, outOf: number, places?: number): string => {
		return TruncateDecimal(100 * (n / outOf), places || 0) + "%";
	};

	const inversePercent = (
		n: number,
		outOf: number,
		places?: number
	): string => {
		return percent(outOf - n, outOf, places);
	};

	// Percentage of seconds over the last 30 days when
	// the last 30 days no matter which month it happens to be when
	// we calculate this.
	const { downtime } = useGetUptime(id, (data, apiStatus) => {
		if (apiStatus.isSuccess && data) {
			return { downtime: data.downtime };
		}
		return { downtime: undefined };
	});

	const uptimeValue =
		downtime !== undefined
			? inversePercent(
					downtime,
					secondsInMonth,
					3 // Calculate to 3 decimal places so we can boast 5 nines
			  )
			: undefined;

	if (!uptimeValue) {
		return null;
	}
	return (
		<>
			<UptimeIcon className="uptime" aria-hidden="true" />
			<Typography
				data-testid={"uptime-" + id}
				color="textSecondary"
				aria-label={`Engine uptime is ${uptimeValue}`}
			>
				{` ${uptimeValue}`}
			</Typography>
		</>
	);
};

export default EngineUptime;
