import { IconButton, TableCell, TableRow, Box } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useState } from "react";
import { Token } from "../../../../api/api.generated";
import { useExistingOrganizationMutator } from "../../../../api/organization";
import { useOkCancelDialog } from "../../../../providers/ok-cancel-dialog/ok-cancel-dialog-provider";
import TokenVisibility from "./token-visibility";

const confirmDeleteTitle = "Are you sure you want to delete this token?";
const confirmDeleteContent =
	"This token is in use by one or more engines. Deleting this token will prevent those engines from connecting to Console. It is recommended to update those engines to use a different token before deleting this token.";

type TokenRowProps = { org: string; token: Token };

/**
 * @param props the parameters as TokenRowProps
 * @returns a token row on the manage page
 */
const TokenRow = (props: TokenRowProps) => {
	const existingOrganizationMutator = useExistingOrganizationMutator(
		props.org
	);

	const [deleteTokenDisabled, setDeleteTokenDisabled] = useState(false);

	const performDelete = () => {
		setDeleteTokenDisabled(true);
		existingOrganizationMutator.deleteToken(props.token.name);
	};
	const { openDialog } = useOkCancelDialog();

	return (
		<TableRow data-testid="token-row">
			<TableCell
				data-testid={"token-name-" + props.token.name}
				sx={{
					pb: { xs: 3, sm: 0 },
				}}
			>
				{props.token.name}
				<Box sx={{ display: { sm: "none" } }}>
					<TokenVisibility org={props.org} token={props.token} />
				</Box>
			</TableCell>
			<TableCell
				data-testid={"token-value-" + props.token.name + "-sm"}
				sx={{
					display: { xs: "none", sm: "table-cell" },
				}}
			>
				<TokenVisibility org={props.org} token={props.token} />
			</TableCell>
			<TableCell
				align="right"
				sx={{
					pb: { xs: 3, sm: 0 },
				}}
			>
				<IconButton
					color="primary"
					size="large"
					data-testid={"del-token" + props.token.name}
					onClick={async () => {
						if (props.token.engines.length > 0) {
							const dialogResult = await openDialog(
								confirmDeleteTitle,
								confirmDeleteContent
							);
							if (dialogResult) {
								performDelete();
							}
						} else {
							performDelete();
						}
					}}
					disabled={deleteTokenDisabled}
					aria-label={`Delete the token: ${props.token.name}`}
				>
					<DeleteOutlineIcon fontSize="inherit" />
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default TokenRow;
