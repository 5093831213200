import {
	Box,
	IconButton,
	Typography,
	Chip,
	Accordion,
	AccordionSummary,
	AccordionActions,
	AccordionDetails,
} from "@mui/material";
import {
	Launch,
	DeleteOutline,
	ArrowForwardIosSharp,
} from "@mui/icons-material";
import { useState } from "react";
import { Engine } from "../../../../../api/api.generated";
import { useExistingEngineMutator } from "../../../../../api/engine";
import { useOkCancelDialog } from "../../../../../providers/ok-cancel-dialog/ok-cancel-dialog-provider";
import { timeAgo } from "../../../../../utils/duration-util";

const confirmDeleteTitle = "Are you sure you want to delete this engine?";
const confirmDeleteContent =
	"Deleting this engine will delete all of its historic data. Do you want to continue?";

type EngineAccordionRowProps = { engine: Engine };

/**
 * @param props the props as EngineAccordionRowProps
 * @returns an engine row on the manage page
 */

const EngineAccordionRow = (props: EngineAccordionRowProps) => {
	const { openDialog } = useOkCancelDialog();
	const existingEngineMutator = useExistingEngineMutator(props.engine.id);

	const [deleteEngineDisabled, setDeleteEngineDisabled] = useState(false);

	const performDelete = () => {
		setDeleteEngineDisabled(true);
		existingEngineMutator.deleteEngine();
	};

	return (
		<Box>
			<Accordion
				elevation={0}
				disableGutters
				data-testid={"engine-name-" + props.engine.name}
				sx={{ bgcolor: "transparent" }}
			>
				<AccordionSummary
					data-testid="engine-name"
					expandIcon={
						<ArrowForwardIosSharp sx={{ fontSize: "0.9em" }} />
					}
					sx={[
						{
							"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
								{
									transform: "rotate(90deg)",
								},
						},
						{
							flexDirection: "row-reverse",
							justifyContent: "space-between",
							pl: 0,
						},
					]}
				>
					<Typography
						sx={{
							width: "100%",
							fontWeight: "bold",
							alignSelf: "center",
							ml: 1,
						}}
					>
						{props.engine.name}
					</Typography>
					<AccordionActions
						sx={{ width: "100%", alignContent: "center" }}
					>
						{props.engine.link && (
							<IconButton
								href={props.engine.link}
								color="primary"
								className="header-link"
								data-testid="engine-link"
								sx={{ padding: "0.4rem" }}
								aria-label={`Link to ${props.engine.name}'s management console`}
							>
								<Launch />
							</IconButton>
						)}
						<IconButton
							data-testid={"del-engine-" + props.engine.name}
							color="primary"
							size="large"
							sx={{ padding: "0.4rem" }}
							onClick={async () => {
								const dialogResult = await openDialog(
									confirmDeleteTitle,
									confirmDeleteContent
								);
								if (dialogResult) {
									performDelete();
								}
							}}
							disabled={deleteEngineDisabled}
							aria-label={`Delete the engine: ${props.engine.name}`}
						>
							<DeleteOutline fontSize="inherit" />
						</IconButton>
					</AccordionActions>
				</AccordionSummary>
				<AccordionDetails sx={{ py: 0, ml: 0.9 }}>
					<Typography data-testid="engine-version">
						Version: {props.engine.version}
					</Typography>
					<Typography data-testid="engine-token">
						Token: {props.engine.tokenName}
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}
						gap="0.4em"
					>
						<Typography data-testid="engine-tags">Tags:</Typography>
						{props.engine.tags.map((tag) => (
							<Chip
								key={tag}
								label={tag}
								color="primary"
								sx={{ my: 0.5 }}
							/>
						))}
					</Box>
					<Typography data-testid="lastUpdate">
						Last Updated:{" "}
						{props.engine.lastUpdate
							? timeAgo(props.engine.lastUpdate) + " ago"
							: "Never"}
					</Typography>
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};

export default EngineAccordionRow;
