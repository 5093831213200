import { useGetLatestStatistic } from "../../../api/engine";
import { Statistics } from "../../../api/api.generated";
import {
	ParseStatType,
	ERROR_QUEUE_COUNT,
	ERROR_QUEUE_ATT,
} from "../../../rhapsody-stats";
import { ReactComponent as ErrorQueueIcon } from "../../../assets/icons/error-queue.svg";
import { Typography, Button, Tooltip } from "@mui/material";

type ErrorQueueProps = {
	id: string;
	link: string;
};

// Path of the error queue page in Rhapsody WMC
export const ERROR_QUEUE_PATH = "/search/ViewErrorQueue.action";

const GetErrorQueueCount = (statistic?: Statistics): number | null => {
	if (!statistic) {
		return null;
	}

	const data = ParseStatType(statistic, ERROR_QUEUE_COUNT, ERROR_QUEUE_ATT);
	return data ? data[0][1] : null;
};

/**
 * Button with icon that displays error queue count of the engine.
 * Links to error queue in WMC when clicked.
 *
 * @param param0 parameters as ErrorQueueProps
 * @returns error queue element
 */
const ErrorQueue = ({ id, link }: ErrorQueueProps) => {
	const { errorCount } = useGetLatestStatistic(
		id,
		(data, apiStatus) => {
			if (apiStatus.isSuccess && data) {
				return {
					errorCount: GetErrorQueueCount(data),
				};
			}
			return { errorCount: undefined };
		},
		[ERROR_QUEUE_COUNT]
	);

	// Does not render this component if zero error count
	if (!errorCount) {
		return null;
	}

	return (
		<>
			<Tooltip title="Error queue">
				<Button
					className="error-queue"
					variant="text"
					startIcon={<ErrorQueueIcon />}
					href={link && `${link}${ERROR_QUEUE_PATH}`}
					target="_blank"
					rel="noreferrer"
					data-testid="error-queue"
					aria-label={`Error queue: ${errorCount}`}
				>
					<Typography
						color="textSecondary"
						data-testid="error-queue-text"
					>
						{errorCount}
					</Typography>
				</Button>
			</Tooltip>
		</>
	);
};

export default ErrorQueue;
