import { Box, Button, TextField } from "@mui/material";
import { FormEvent, useState } from "react";
import {
	useExistingOrganizationMutator,
	validTokenNameRegex,
} from "../../../../api/organization";

type CreateTokenProps = {
	org: string;
};

/**
 * @param props the props as CreateTokenProps
 * @returns the tokens of an organization on the manage page
 */
const CreateToken = (props: CreateTokenProps) => {
	const tokenMutator = useExistingOrganizationMutator(props.org);

	const [createTokenDisabled, setCreateTokenDisabled] = useState(true);
	const [createTokenName, setCreateTokenName] = useState("");

	const submitToken = (e: FormEvent) => {
		e.preventDefault();
		setCreateTokenDisabled(true);
		const newName = createTokenName;
		setCreateTokenName("");
		tokenMutator.postToken(newName);
	};

	return (
		<Box
			display="flex"
			alignItems="center"
			gap="1em"
			component="form"
			onSubmit={submitToken}
			paddingBottom="0.6rem"
		>
			<Box sx={{ width: "100%", flexGrow: { xs: 1, md: 0 } }}>
				<TextField
					size="small"
					sx={{ width: "100%" }}
					value={createTokenName}
					onChange={(e) => {
						setCreateTokenName(e.target.value);
						setCreateTokenDisabled(
							!validTokenNameRegex.test(e.target.value)
						);
					}}
					label="Name"
					data-testid="token-create-value"
				/>
			</Box>
			<Button
				type="submit"
				disabled={createTokenDisabled}
				variant="contained"
				color="primary"
				data-testid="token-create-button"
				sx={{ width: "6rem" }}
			>
				CREATE
			</Button>
		</Box>
	);
};

export default CreateToken;
